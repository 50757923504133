<template>
	<div class="animated fadeIn">
		<template v-if="admin.includes(this.$user.details().type)">
			<b-alert
				show
				variant="secondary">
				<div class="row">
					<div class="col">
						<search
							ref="searchComp"
							v-model="distributor"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:default-params="{role:'distributor,pre_distributor'}"
							:placeholder="'autocomplete_placeholder'"
							:init-value="String(distributor)"
							return-key="id"
							class="mb-3"
							q="label" />
						<div class="row">
							<div class="col">
								<b-button
									:disabled="loading"
									variant="primary"
									class="btn mx-1"
									@click="changeDistributor()">
									{{ translate('search') }}
								</b-button>
								<b-button
									:disabled="loading"
									variant="secondary"
									class="btn mx-1"
									@click="clearDistributor()">
									{{ translate('clear') }}
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</b-alert>
			<hr>
		</template>
		<div class="row">
			<div class="col">
				<div
					:class="!showFilters ? 'btn-primary' : 'btn-secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</div>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div :class="false && dateRange === dateRanges.custom ? 'col-md-3 col-sm-6 col-xs-12' : 'col-md-3 col-sm-6 col-xs-12' ">
						<search
							v-model="filters.username"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor,pre_distributor', tree:'unilevel'}"
							class="mb-3"
							q="label"
							return-key="username" />
					</div>
					<div :class="false && dateRange === dateRanges.custom ? 'col-md-3 col-sm-6 col-xs-12' : 'col-md-3 col-sm-6 col-xs-12' ">
						<div
							v-if="countries !== null"
							class="form-group">
							<label
								for="country"
								class="label">{{ translate('country') }}</label>
							<select
								id="country"
								v-model="filters.country"
								:disabled="!countries.length || staffCountry"
								name="country"
								class="form-control">
								<template v-if="!staffCountry">
									<option :value="'' || undefined">
										{{ translate('all') }}
									</option>
									<template v-if="countries.length">
										<option
											v-for="country in countries"
											:key="country.attributes.code"
											:value="country.attributes.code">
											{{ translate(country.attributes.code.toLowerCase()) }}
										</option>
									</template>
								</template>
								<template v-if="staffCountry">
									<option :value="countryCode">
										{{ translate(countryCode.toLowerCase()) }}
									</option>
								</template>
							</select>
						</div>
					</div>
					<div :class="false && dateRange === dateRanges.custom ? 'col-md-3 col-sm-6 col-xs-12' : 'col-md-3 col-sm-6 col-xs-12' ">
						<div
							class="form-group">
							<label
								for="status"
								class="label">{{ translate('status') }}</label>
							<select
								id="status"
								v-model="filters.status"
								name="status"
								class="form-control">
								<option
									:value=" '' || undefined ">
									{{ translate('all') }}
								</option>
								<option
									v-for="status in statuses"
									:key="status"
									:value="status">
									{{ translate(status) }}
								</option>
							</select>
						</div>
					</div>
					<div
						:class="false && dateRange === dateRanges.custom ? 'col-md-3 col-sm-6 col-xs-12' : 'col-md-3 col-sm-6 col-xs-12' ">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="false && dateRange === dateRanges.custom"
						class="col-md-3 col-sm-6 col-xs-12">
						<label
							for="startDate"
							class="label">{{ translate('start_date') }}</label>
						<v-date-picker
							id="startDate"
							v-model="startDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="startDate"
							show-caps />
					</div>
					<div
						v-if="false && dateRange === dateRanges.custom"
						class="col-md-3 col-sm-6 col-xs-12">
						<label
							for="endDate"
							class="label">{{ translate('end_date') }}</label>
						<v-date-picker
							id="endate"
							v-model="endDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="endDate"
							show-caps />
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('id')">
							{{ translate('user_id') }}
							<sort field="id" />
						</th><th
							class="border-top-0 pointer"
							@click="sortByField('username')">
							{{ translate('username') }}
							<sort field="username" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('full_name')">
							{{ translate('name') }}
							<sort field="full_name" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('email')">
							{{ translate('email') }}
							<sort field="email" />
						</th>
						<th
							class="border-top-0">
							{{ translate('mobile_number') }}
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('country')">
							{{ translate('country') }}
							<sort field="country" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('status')">
							{{ translate('status') }}
							<sort field="status" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('product_id')">
							{{ translate('package') }}
							<sort field="product_id" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('created_at')">
							{{ translate('joining_date') }}
							<sort field="created_at" />
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle">
							<span
								v-if="admin.includes($user.details().type)"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.id.toString() })">
								{{ item.id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.id }}
							</span>
						</td>
						<td class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							{{ item.attributes.name }}
						</td>
						<td class="align-middle">
							{{ item.attributes.email }}
						</td>
						<td class="align-middle">
							{{ item.attributes.mobile_number }}
						</td>
						<td class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(item.attributes.country.toLowerCase())"
								:src="require(`@/assets/images/common/countries/select/${item.attributes.country.toUpperCase()}.png`)"
								:alt="translate(item.attributes.country.toLowerCase())">
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.status.toLowerCase()) }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.package.toLowerCase()) }}
						</td>
						<td class="align-middle">
							{{ $moment(item.attributes.joining_date.date).format(dateFormat) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<isLoading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>

import { SEARCH_USERS } from '@/config/endpoint';
import { PAGINATION } from '@/settings/RequestReply';
import { admin } from '@/settings/Roles';
import { STATUSES as statuses } from '@/settings/Statuses';
import { YMDHMS_FORMAT, DATE_RANGES as dateRanges, V_CALENDAR_CONFIG } from '@/settings/Dates';
import {
	Network, Countries, Profile, Users, Tooltip, Grids, Packages,
} from '@/translations';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import FiltersParams from '@/mixins/FiltersParams';
import DataFilter from '@/components/DataFilter';
import Distributor from '@/util/Network';
import Country from '@/util/Country';
import Sponsored from './mixins/Sponsored';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'SponsoredDistributors',
	messages: [Network, Countries, Profile, Users, Tooltip, Grids, Packages],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, Sponsored, DashboardRedirect],
	data() {
		return {
			user: new Distributor(),
			statuses,
			dateRanges,
			alert: new this.$Alert(),
			dateFormat: YMDHMS_FORMAT,
			endpoint,
			countryData: new Country(),
			calendarConfig: V_CALENDAR_CONFIG,
			admin,
		};
	},
	computed: {
		loading() {
			return !!this.user.data.loading;
		},
		errors() {
			return this.user.data.errors;
		},
		pagination() {
			return this.user.data.pagination;
		},
		data() {
			try {
				const { data } = this.user.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		username() {
			try {
				return this.user.data.response.data.meta.username;
			} catch (error) {
				return this.$user.details().type;
			}
		},
		countries() {
			try {
				return this.countryData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		staffCountry() {
			if (this.admin.includes(this.$user.details().type)) {
				return this.$user.details().country !== undefined;
			}
			return false;
		},
		countryCode() {
			return this.$user.details().country.iso_code_2;
		},
	},
	mounted() {
		this.countryData.getCountries();
		this.searchDistributorsSponsoredDistributors();
		if (this.staffCountry) {
			this.filters.country = this.countryCode;
		}
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.user.getSponsoredDistributors(this.$user.details().id, options);
		},
		searchDistributorsSponsoredDistributors() {
			let { distributorId } = this.$route.params;
			if (typeof distributorId === 'undefined') {
				distributorId = this.$user.details().id;
			}
			this.user.getSponsoredDistributors(distributorId);
		},
	},
};
</script>
